<template>
  <div class="content-box">
    <div class="content-header">
      <img src="@/assets/image/btceyes/btceyes.png" alt="" style="margin: 0 10px;">
    </div>
    <div class="content-main">
      <div>
        <btc-video v-if="home.VideoLink" :item="home"/>
        <div style="padding: 0 10px; font-size: 12px;">
          <h6 style="color: #014479;">{{ home.NewsTitle }}</h6>
          <div class="time">
            <div class="btc-triangle triangle-point-right tc-1" />
            <span>{{ home.NewsDateTime }}</span>
          </div>
        </div>
      </div>
      <div v-if="list.length" class="main-right">
        <btc-video-card v-for="(item, index) in list" :key="index" :item="item" :active="current===index" @handle-video="handleVideo($event, index)" />
        <div class="more hand" @click="$router.push('/BTCEyes/Videos')">Show More</div>
      </div>
    </div>
  </div>
</template>

<script>
import BtcVideo from '@/components/BtcVideo'
import BtcVideoCard from '@/components/btc-video-card'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'BTCEyes',
  components: {
    BtcVideo,
    BtcVideoCard
  },
  data () {
    return {
      list: [],
      home: {},
      current: 0,
      listQuery: {
        PageSize: 7,
        PageNum: 1,
        UserID: getLocalStore().UserID,
        KeyWords: '',
        NewsType: 'beefEn',
        // NewsLanguageCode: 'en',
        SortOrder: 'descending',
        IsPushOut: false
      }
    }
  },
  computed: {},
  watch: {},
  mounted () {
    if (Object.keys(this.$route.query).length !== 0) {
      this.list = this.$route.query.list
      this.home = this.list[0]
      // 清空路由所携带的值
      this.$router.push({ query: {} })
    } else {
      this.getEyesList()
    }
  },
  methods: {
    getEyesList () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetEyes', this.listQuery).then(() => {
        this.list = this.$store.getters.eyesRows
        this.current = this.list.filter(v => { return v.IsTopNews === true }).length
        this.home = this.list[this.current]
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.error(err)
      })
    },
    handleVideo (item, index) {
      this.current = index
      this.home = item
    }
  }
}
</script>

<style scoped>
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  background-color: #f5f5f5;
  box-shadow: 2px 2px 5px #e0e0e0;
}

.content-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.content-main {
  min-height: 623px;
  width: 1248px;
  /* height: 600px; */
  display: flex;
  background: #ffffff;
  border-radius: 20px;
}

.main-right {
  width: 350px;
  /* height: 580px; */
  /* background: chocolate; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px 10px 10px 0; */
  overflow: hidden;
  padding: 10px;
}

.time {
  display: flex;
  align-items: center;
}
.more {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 40px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #e0e0e0;
  margin: 10px 0;
}
</style>
